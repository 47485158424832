section.cool-box {
	$img-margin: 1.5rem;

	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	min-height: 5rem;

	> .img {
		flex-shrink: 0;
		max-width: 100%;
		max-height: 100%;
		width: 30rem;
		height: 20rem;

		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}

	@media screen and (min-width: 50rem) {
		&:nth-of-type(odd) {
			> .img {
				margin-right: $img-margin;
			}
		}

		&:nth-of-type(even) {
			> .img {
				order: 2;
				margin-left: $img-margin;
			}
		}
	}

	> .text {
		flex-basis: 30rem;
		flex-grow: 1;

		h2 {
			font-size: 2.25rem;
			color: #676767;
		}
	}
}