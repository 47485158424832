form {
	margin-bottom: 1.25rem;	
}

.gform_wrapper {
	margin: 0px;
	padding: 0px;
	max-width: 100%;
	
	form {			

		.validation_error {
			text-align: center;
		}
		
		.gform_body {
			
			.gform_fields {
				
				.gfield {
					margin: 0 0 1rem 0;
					padding: 0;
					border-style: none;
					
					&::before {
						display: none;
					}
					
					.gfield_label {
						margin: 0 0 0 1rem;
						color: #5e001d;
						
						.gfield_required {
							
						}
					}
					
					.ginput_container {
						margin: 0;
						
						input[type="text"],
						input[type="email"],
						textarea {
							padding: 10px 30px 10px 20px;
							width: 100%;
							border-style: none;
							border-radius: 25px;
							background-color: #f2f2f2;
							line-height: 1.3rem;
							
							&:focus {
								background-color: #fff;
								-webkit-box-shadow: 0 0 20px 5px rgba(94,0,29,0.3);
								box-shadow: 0 0 20px 5px rgba(94,0,29,0.3);
							}
						}
					}
					
					&.gfield_error {
						margin: 0px;
						width: 100%;
						background-color: transparent;
						
						.ginput_container {
							max-width: 100%;
							margin: 0px;
						
							input[type="text"],
							input[type="email"],
							textarea {
								margin: 0px;
								background-color: #FFDFE0;
							}
						}
						
						.validation_message {
							display: none;
							margin: 0px;
							padding: 0px !important;
							max-width: 100%;
						}
					}
				}
			}
		}
		
		.gform_footer {
			margin: 0px;
			padding: 0px;
			
			button,
			.button {
				padding: 12px 45px 12px 45px;
				text-transform: uppercase;
				text-align: center;
				font-size: 0.75rem;
				color: #5e001d;
				background-color: #fff;
				border-radius: 30px;
				border: solid 1px #c2c2c2;
				
				&:hover {
					color: #fff;
					background-color: #5e001d;
					border: solid 1px #5e001d;
				}
			}
		}
	}
}