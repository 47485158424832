/* Base Styles
 * The styles that go here should be site-global and not specific to any individual components.
 */

html, body {
	overflow-x: hidden;
}

body {
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	line-height: 1.3;
	background-color: $color-slate;
}

a {
	transition: all .2s ease-in-out;
}

input,
textarea,
button {
	outline: none;
}