section.testimonials {
	> .content {
		position: relative;
		width: 100%;
		max-width: $row-width * 9 / 12; // ~47.5rem
		margin: 0 auto;

		> .controls {
			position: absolute;
			left: 0;
			top: calc(50% - 0.5em);
			width: 100%;
			font-size: 2rem;

			@media screen and (max-width: 32rem) {
				top: 1em;
			}

			> .left,
			> .right {
				position: relative;
				font-family: FontAwesome;

				display: inline-flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				vertical-align: middle;

				width: 1.5em;
				height: 1.5em;

				background-color: $color-burnt-red;
				color: white;
				border-radius: 100%;

				transition: background-color 0.15s ease-in-out;

				&:hover {
					background-color: lighten($color-burnt-red, 10%);
				}
			}

			> .left {
				// right: 2em;

				&::before {
					content: "\f104";
				}
			}

			> .right {
				// left: 2em;

				&::before {
					content: "\f105";
				}
			}
		}

		> .list {
			position: relative;
			margin: 0 4rem;

			transition: height 0.2s ease-in-out;

			> .testimonial {
				position: absolute;
				top: 0;
				left: 0;

				width: 100%;
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s;

				opacity: 0;
				visibility: hidden;

				&[data-state="in"] {
					transition-delay: 0s;
					visibility: visible;
					opacity: 1;
				}

				> .logo {
					flex-grow: 1;
					flex-basis: 10rem;
					margin-right: 1rem;

					height: 100vh;
					max-height: 6.5rem;

					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
				}

				> blockquote {
					flex-grow: 1;
					flex-basis: 34rem;
					padding: 0 1rem;

					@media screen and (max-width: 32rem) {
						margin: 0 -4rem;
					}

					> .content {
						font-style: italic;
						font-size: 1.125rem;
						line-height: 1.6;
						color: $color-slate;

						&::before, &::after {
							display: inline-block;
							margin-bottom: -0.5em;
							line-height: 1;

							font-family: "Hevetica Regular", Arial, sans-serif;
							font-size: 3.2rem;
							font-style: normal;
							color: $color-burnt-red;
							vertical-align: bottom;
						}

						&::before {
							margin-right: 0.6rem;
							content: "“";
						}

						&::after {
							margin-left: 0.6rem;
							content: "”";
						}

						.read-more-testimonial {
							color: $color-burnt-red;
							font-weight: bold;
							display: inline-block;
						}
					}

					> cite {
						display: block;
						margin-top: 1rem;
						font-size: 0.8rem;
						color: $color-burnt-red;

						&::before {
							content: "—";
							margin-right: 0.3rem;
						}
					}
				}
			}
		}
	}
}