body > header.mobile {
	$nav-toggle-size: 2.25rem; // Size of hamburger

	position: fixed;
	z-index: 95;
	top: 0;
	left: 0;
	display: none;
	padding: 0.1rem 0;
	width: 100%;

	background-color: white;
	box-shadow: 0px 2px 12px rgba(black, 0.2), 0px 0px 4px rgba(black, 0.6);

	@include with-touch-nav {
		display: flex;
	}

	// The menu toggle button
	.toggle-off-canvas {
		flex-shrink: 0;

		font-weight: 100;
		font-size: $nav-toggle-size;
		cursor: pointer;

		&::before {
			font-family: FontAwesome;
			content: "\f0c9";
			width: $nav-toggle-size;
			height: $nav-toggle-size;
			margin: 0 0.5rem;
			vertical-align: top;
		}
	}

	.logo-container {
		display: flex;
		justify-content: center;
		align-items: center;

		flex-grow: 1;

		img {
			max-height: 2.5rem;
		}
	}
}

body > .mobile-off-canvas {
	z-index: 105;
	position: fixed;
	top: 0;
	left: -100%;

	width: 60vw;
	min-width: 280px;
	max-width: 400px;
	height: 100vh;

	background-color: white;

	transition: left 0.5s ease-in-out;

	&.active {
		left: 0;
	}

	header {
		padding: 0.5rem;

		border-bottom: 1px solid black;

		text-align: center;

		img {
			max-height: 2.5rem;
		}
	}

	nav {
		display: flex;
		flex-direction: column;
		font-size: 1.5rem;

		> a {
			display: block;
			padding: 1rem;
			border-bottom: 1px solid black;

			transition: background-color 0.2s ease-in-out;

			&:focus {
				background-color: rgba(black, 0.2);
			}

			&:hover, &:active {
				background-color: rgba(black, 0.3);
			}
		}
	}
}