main.front {
	padding-top: 55px;
	padding-bottom: 65px;

	// THE ULTRA NAV
	> nav.ultra {
		width: 100%;
		max-width: $row-width;
		margin: 0 auto;

		ul.menu {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			text-align: center;

			> li {
				flex-basis: 12rem;
				flex-shrink: 0;
				flex-grow: 1;
				text-align: center;

				> a {
					display: inline-block;
					margin: 0rem 5rem;

					font-size: 2rem;
					color: $color-comet;
					text-align: center;
					
					&::before {
						display: block;
						padding-bottom: 0.5em;
						font-family: FontAwesome;
						font-size: 2.5rem;
					}
					
					&:hover {
						color: $color-burnt-red;
					}
				}
				
				&.hardware {
					a {
						&::before {
							content: "\f1c0";
						}
					}
				}
				
				&.software {
					a {
						&::before {
							content: "\f121";
						}
					}
				}
				
				&.security {
					a {
						&::before {
							content: "\f0c1";
						}
					}
				}
				
				&.service {
					a {
						&::before {
							content: "\f007";
						}
					}
				}
			}
		}

		.sub-menu {
			display: none;
		}
	}
}