.testimonials {
	padding-top: 55px;
	padding-bottom: 55px;
	background-color: $color-zircon;
	
	img {
		max-width: 125px;
		height: auto;
	}
}

body > footer {
	padding: 30px 0px;
	font-size: 14px;
	font-weight: 100;
	color: #fff;
	text-align: center;
}