.banner {
	position: relative;
	height: 300px;
	background-color: #999;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	
	&:after {
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		width: 100%;
		height: 100%;
		background-color: rgba(#41485b,0.75);
	}
	
	.row {
		position: relative;

		h1 {
			position: absolute;
			z-index: 99;
			top: 225px;
			padding-left: 15px;
			font-size: 3rem;
			font-weight: 300;
			line-height: 3.75rem;
			color: #fff;
		}
	}
}

main {
	background-color: #fff;
	
	&.page {
		padding-top: 3.125rem;
		padding-bottom: 3.125rem;
		font-size: 1rem;
		line-height: 1.875rem;
		color: #676767;
	}
}