body > header.desktop {
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;

	@include with-touch-nav {
		display: none;
	}

	&.home {
		margin-top: 2.75rem;
	}

	&.internal {
		> nav.upper {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			margin: 0 auto;
			max-width: $row-width;

			> a {
				color: white;
				padding: 0.75rem;

				transition: all 0.15s ease-in-out;

				&::before {
					color: #9da1ad;
				}

				&:hover {
					background: rgba(black, 0.12);
				}
			}
		}
	}

	> .primary {
		background-color: white;

		> .content {
			display: flex;
			align-items: stretch;

			margin: 0 auto;
			max-width: $row-width;

			> .logo {
				display: inline-block;
				position: relative;
				top: -20px;
				margin-bottom: -44px;
			}

			> nav {
				display: flex;
				justify-content: flex-end;

				flex-grow: 1;

				> a {
					display: flex;
					align-items: center;
					padding: 0 1rem;

					font-weight: 400;
					color: $color-burnt-red;

					transition: all 0.15s ease-in-out;

					&:hover {
						color: $color-burnt-red;
						background-color: rgba(black, 0.12);
					}
				}
			}
		}
	}

	nav > a {
		&::before {
			display: inline;
			padding-right: 0.6rem;
			font-family: FontAwesome;
			content: "X";
		}

		&.email::before {
			content: "\f003";
		}

		&.phone::before {
			content: "\f095";
		}

		&.support::before {
			content: "\f132";
		}

		&.hardware::before {
			content: "\f1c0";
		}

		&.software::before {
			content: "\f121";
		}

		&.security::before {
			content: "\f0c1";
		}

		&.service::before {
			content: "\f007";
		}
	}
}