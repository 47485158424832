%wysiwyg, .wysiwyg {

	h1, h2 {
		margin-bottom: 1.25rem;
		font-size: 2.5rem;
		font-weight: 300;
		color: #676767;
	}
	
	h3 {
		margin-top: 3.0rem;
		margin-bottom: 1.25rem;
		font-size: 2.25rem;
		font-weight: 300;
		color: #676767;
	}
	
	h4 {
		margin-bottom: 1.25rem;
		font-size: 1.875rem;
		font-weight: 300;
		color: #676767;
	}
	
	h5 {
		margin-bottom: 1.25rem;
		font-size: 1.5rem;
		font-weight: 300;
		color: #676767;
	}
	
	h6 {
		margin-bottom: 1.25rem;
		font-size: 1.313rem;
		font-weight: 300;
		color: #676767;
	}
	
	p {
		margin-bottom: 1.25rem;
	}
	
	ul,
	ol {
		margin-bottom: 1.25rem;
		margin-left: 1.875rem;
		line-height: 1.5rem;
		
		> li {
			margin-bottom: 0.5rem;
			
			> ul,
			> ol {
				
				margin-bottom: -0.5rem;
				margin-left: 0rem;
				
				> li {
					margin-bottom: 0rem;
					
					&::before {
						display: none;
					}
				}
			}
		}
	}
	
	ul {
		margin-left: 0rem;
		list-style-type: none;
		
		> li {
			position: relative;
			margin-left: 0rem;
			padding-left: 1.5625rem;
			
			&::before {
				margin-right: 0.3125rem;
				position: absolute;
				left:0;
				top: 0;
				font-family: fontawesome;
				content: "\f192";
				color: #5e001d;
			}

			> ul {
				list-style-type: square;
				
				> li {
					margin-left: 1rem;
					padding-left: 0.5rem;
				}
			}
		}
	}
	
	ol {
		margin-left: 0rem;
		list-style-type: none;
		counter-reset: ge-ol;
		
		> li {
			position: relative;
			margin-left: 0rem;
			padding-left: 1.5625rem;
			
			&::before {
				margin-right: 0.3125rem;
				content: counter(ge-ol) ". ";
				counter-increment: ge-ol;
				position: absolute;
				left:0;
				top: 0;
				font-weight: 700;
				color: #5e001d;
			}
			
			> ol {
				list-style-type: lower-alpha;
				
				> li {
					margin-left: 1rem;
					padding-left: 0.5rem;
				}
			}
		}
	}
	
	a {
		color: #5d051e;
		text-decoration: underline;
		
		&:hover {
			text-decoration: none;
		}
		
		&.button {
			display: inline-block;
			margin-bottom: 1.25rem;
			padding: 18px 40px 18px 40px;
			text-transform: uppercase;
			text-align: center;
			text-decoration: none;
			font-size: 0.75rem;
			color: #5e001d;
			background-color: #fff;
			border-radius: 30px;
			border: solid 1px #c2c2c2;
			
			&:hover {
				color: #fff;
				background-color: #5e001d;
				border: solid 1px #5e001d;
			}
		}
	}
	
	strong, b {
		font-weight: 700;
	}
	
	em, i {
		font-style: italic;
	}
}