gecko-slideshow {
	display: block;
	position: relative;
	z-index: 10;

	&:not([data-bound]) {
		visibility: hidden;
	}

	> .viewport {
		position: relative;
		width: 100%;
		height: calc(100vh - 250px);
		min-height: 200px;

		> gecko-slide {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;

			transition: opacity 1s ease-in-out, visibility 0s linear 1s;

			&[data-state="exiting"] {
				z-index: 1;
			}

			&:not([hidden]) {
				transition-delay: 0s;
			}

			&[hidden] {
				z-index: 0;
				visibility: hidden;
				opacity: 0;
			}
		}
	}

	> .controls {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 15;

		> gecko-dots {
			display: flex;
			justify-content: center;
			z-index: 3;
			padding: 1rem;

			> .dot {
				border-radius: 100%;
				margin: 1rem;
				width: 1rem;
				height: 1rem;

				background-color: #5D051E;

				transition: background-color 0.3s ease-in-out;

				&[data-selected] {
					background-color: white;
				}
			}
		}
	}
}